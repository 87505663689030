/*
* this factory adds react-hot-loader with redbox-react
* and the react-redux provider
* */
import { Provider } from 'react-redux'
import React from 'react'
// import ReactDOM from 'react-dom'
import ReactDOM from 'react-dom'

/**
 * Wraps a React Element with the react hot loader AppContainer Component
 * @param {object}	c the React Element to wrap
 */
const wrapWithHotLoader = c => {
  if (module.hot) {
    const { AppContainer } = require('react-hot-loader')
    const CustomErrorReporter = require('./CustomErrorReporter').default

    return <AppContainer errorReporter={CustomErrorReporter}>{c}</AppContainer>
  }
  return c
}

export default class DomFactory {
  constructor(store = null) {
    /**
     * The redux store
     */
    this.store = store
  }

  /**
   * Injects a react component
   * @param {object}			module		- The react component
   * @param {object}			props		- Props to initiate component with
   * @param {HTMLElement}		target		- The target element to inject to
   */
  inject(module, props = {}, target) {
    if (target) {
      const root = ReactDOM.createRoot(document.getElementById('root'))
      root.render(
        <Provider store={this.store}>
          {wrapWithHotLoader(React.createElement(module, props || {}))}
        </Provider>
      )
    } else {
      // eslint-disable-next-line
      console.warn('RHW07', 'Target element is null or undefined.')
    }
  }

  /**
   *  Disposes a react component
   * @param {HTMLElement}		target		- The target element to dispose
   */
  dispose(target) {
    if (target) {
      root.unmount()
    }
  }
}
